import axios from 'axios';
import { getCookie, setCookie } from 'typescript-cookie';

let access_token = getCookie('access_token');
// console.log(access_token);
if(!access_token){
    access_token = '';
}
export default axios.create({
  baseURL: 'https://userapi.thevfx.studio/',
  // baseURL: "http://localhost:3002/",
  headers: {
    'Content-type': 'application/json',
    'x-access-token' : access_token,
  },
})

// export const baseUrl2 = 'https://arkwrxapi.cafemobility.com/'

// User Server Api

export const baseUrl = "https://userapi.thevfx.studio/";
// export const baseUrl = "http://localhost:3002/";

// Admin Server Api

export const baseUrl2 = 'https://adminapi.thevfx.studio/';
// export const baseUrl2 = "http://localhost:3001/";
