import React from 'react'
import Carousel from 'react-multi-carousel'
import {baseUrl2} from '../../../app/modules/auth/core/ApiAxios'
import {Responsive} from '../../../_metronic/sliders/responsive'
import {Link} from 'react-router-dom'

export default function MainCategories(mainCategories: any) {
  const responsive = Responsive
  return (
    <>
      {/* began:row */}
      <div className='row gx-5 gx-xl-8 mb-5 mb-xl-8'>
        <div className='col-xl-12'>
          {/*begin::Player widget 1*/}
          <div className='card card-flush h-xl-100'>
            {/*begin::Header*/}
            <div className='card-header pt-7'>
              {/*begin::Title*/}
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold text-dark'>Category</span>
                <span className='text-gray-400 mt-1 fw-semibold fs-6'>Category list</span>
              </h3>
              {/*end::Title*/}
            </div>
            {/*end::Header*/}
            {/*begin::Card body*/}
            <div className='card-body pt-7'>
              {/*begin::Row*/}
              <div className='row g-5 g-xl-9 mb-5 mb-xl-9'>
                {/*begin::Col*/}
                <Carousel
                  responsive={responsive}
                  swipeable={true}
                  showDots={true}
                  autoPlay={true}
                  infinite={true}
                  autoPlaySpeed={3000}
                  removeArrowOnDeviceType={['tablet', 'mobile']}
                >
                  {mainCategories.mainCategories?.map((category: any) => {
                    return (
                      <>
                        <div className='col-sm-3 mb-3 mb-sm-0 w-100 pb-5 ps-2 pe-3'>
                          {/*begin::Player card*/}
                          <div className='m-0'>
                            {/*begin::User pic*/}
                            <div
                              style={{backgroundImage: "url('" + baseUrl2 + "')"}}
                              className='card-rounded position-relative mb-5'
                            >
                              {/*begin::Img*/}
                              <div
                                className='bgi-position-center bgi-no-repeat bgi-size-cover h-150px card-rounded'
                                style={{
                                  backgroundImage: `url("${baseUrl2}${category?.category_image}")`,
                                }}
                              />
                              {/*end::Img*/}
                              {/*begin::Play*/}
                              <button
                                className='btn btn-icon h-auto w-auto p-0 ms-4 mb-4 position-absolute bottom-0 right-0'
                                data-kt-element='list-image-button'
                              >
                                <i
                                  className='fonticon-play text-white fs-2x'
                                  data-kt-element='list-image-icon'
                                />
                                <i
                                  className='fonticon-pause text-white fs-2x d-none'
                                  data-kt-element='list-image-icon'
                                />
                              </button>
                              {/*end::Play*/}
                            </div>
                            {/*end::User pic*/}
                            {/*begin::Info*/}
                            <div className='m-0'>
                              {/*begin::Title*/}
                              <Link
                                to={`/sub_categories/${category?.id}`}
                                className='text-gray-800 text-hover-primary fs-3 fw-bold d-block mb-2'
                              >
                                {category?.category_name}
                              </Link>
                              {/*end::Title*/}
                              <span className='fw-bold fs-6 text-gray-400 d-block lh-1'>
                                NEW MEMORIES START
                              </span>
                            </div>
                            {/*end::Info*/}
                          </div>
                          {/*end::Player card*/}
                        </div>
                      </>
                    )
                  })}
                </Carousel>
                {/*end::Col*/}
              </div>
              {/*end::Row*/}
            </div>
            {/*end::Card body*/}
          </div>
          {/*end::Player widget 1*/}
        </div>
      </div>
      {/* end:row */}
    </>
  )
}
