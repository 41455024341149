import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle, PageLink} from '../../../_metronic/layout/core'
import ReactPlayer from 'react-player'
import ApiAxios, {baseUrl2} from '../../../app/modules/auth/core/ApiAxios'
import MainCategories from './MainCategories'
import {Responsive} from '../../../_metronic/sliders/responsive'
import Carousel from 'react-multi-carousel'
import CategoryTopProducts1 from './CategoryTopProducts1'
import CategoryTopProducts2 from './CategoryTopProducts2'
// import {dispatch, useDispatch, useSelector} from '../../../redux/store'

const DashboardPage = () => {
  const responsive = Responsive

  const [getCategories, setCategories] = useState([])
  useEffect(() => {
    getCategoriesData()
  }, [])

  const getCategoriesData = async () => {
    try {
      const getData = await ApiAxios.get('/categories/')
      setCategories(getData.data)
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <>
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-12'>
          <ReactPlayer
            className='react-player'
            url='https://www.youtube.com/embed/TWdDZYNqlg4'
            width='100%'
            height='650px'
          />
        </div>
      </div>
      <MainCategories mainCategories={getCategories} />

      {/* begin::Row */}
      <div className='row g-7 g-xl-5 mb-0 mb-xl-5'>
        {/*begin::Col*/}
        {getCategories?.map((category: any, i) => {
          return (
            <>
              <div className='row g-7 g-xl-2 mb-0 mb-xl-2'>
                {/*begin::Col*/}
                <div className='col-xl-12'>
                  {/*begin::Player widget 1*/}
                  <div className='card card-flush h-xl-100'>
                    <div className='card-header'>
                      <div className='card-title'>
                        <h4 className='card-title d-flex align-items-start flex-column'>
                          <span className='card-label fw-bold text-gray-800'>
                            {category?.category_name} - Horizontal Videos
                          </span>
                        </h4>
                      </div>
                      <div className='card-toolbar'>
                        <div
                          className='d-flex justify-content-end'
                          data-kt-user-table-toolbar='base'
                        >
                          <a href='' className='btn btn-sm btn-light fs-8 fw-bold'>
                            More View
                          </a>
                        </div>
                      </div>
                    </div>
                    <CategoryTopProducts1 cateTopProducts={category.products} />
                    <div className='card-header border-0 pt-0'>
                      <div className='card-title'>
                        <h4 className='card-title d-flex align-items-start flex-column'>
                          <span className='card-label fw-bold text-gray-800'>
                            {category?.category_name} - Verticals Videos
                          </span>
                        </h4>
                      </div>
                      <div className='card-toolbar'>
                        <div
                          className='d-flex justify-content-end'
                          data-kt-user-table-toolbar='base'
                        >
                          <a href='' className='btn btn-sm btn-light fs-8 fw-bold'>
                            More View
                          </a>
                        </div>
                      </div>
                    </div>
                    <CategoryTopProducts2 />
                  </div>
                  {/*end::Player widget 1*/}
                </div>
                {/*end::Col*/}
              </div>
            </>
          )
        })}

        {/*end::Col*/}
        {/*begin::Col*/}
      </div>
      {/* end::Row */}
    </>
  )
}

const accountBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const DashboardWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={accountBreadCrumbs}>{intl.formatMessage({id: 'Home'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
