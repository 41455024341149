import {MenuItem} from './MenuItem'
import {MenuInnerWithSub} from './MenuInnerWithSub'
import MegaMenu from './MegaMenu'
import {useIntl} from 'react-intl'
import React, {useEffect, useState} from 'react'
import ApiAxios from '../../../../app/modules/auth/core/ApiAxios'

export function MenuInner() {
  const intl = useIntl()
  const [getMainCategory, setMainCategory] = useState([])
  const [getSubCategory, setSubCategory] = useState([])
  useEffect(() => {
    async function GetCategories() {
      try {
        const Categories = await ApiAxios.get('/categories/allcategories')
        const MainCategories = await Categories.data?.filter((mc: any) => mc.is_sub === 0)
        const SubCategories = await Categories.data?.filter((mc: any) => mc.is_sub !== 0)
        // console.log(Categories.data);
        setMainCategory(MainCategories)
        setSubCategory(SubCategories)
      } catch (error) {
        console.log(error)
      }
    }
    GetCategories()
  }, [])

  return (
    <>
      <MenuItem title={intl.formatMessage({id: 'Home'})} to='/dashboard' />
      {/* <MenuItem title='Layout Builder' to='/builder' /> */}
      {getMainCategory?.map((MainCategory: any, i) => {
        return (
          <MenuInnerWithSub
            hasArrow={true}
            isMega={true}
            title={MainCategory?.category_name}
            to='/crafted'
            menuPlacement='bottom-start'
            menuTrigger={`{default:'click', lg: 'hover'}`}
            key={i}
          >
            <MegaMenu
              subCategories={getSubCategory?.filter(
                (subc: any) => subc.parent_id === MainCategory.id
              )}
              mainCategory={MainCategory}
            />
          </MenuInnerWithSub>
        )
      })}
    </>
  )
}
